.react-flow .react-flow__pane,
.react-flow .react-flow__edge,
.react-flow .react-flow__node {
  cursor: default;
}

.react-flow .react-flow__handle {
  cursor: pointer;
}

.react-flow .react-flow__handle-left {
  left: 16px;
}

.react-flow .react-flow__handle-right {
  right: 16px;
}

.react-flow .react-flow__edges {
  z-index: 100;
}

.react-flow .react-flow__edge .react-flow__edge-path {
  transition: stroke 0.2s ease-in-out;
}

.react-flow .react-flow__edge .react-flow__edge-path.highlighted {
  pointer-events: none;
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
  stroke-width: 2;
}

.react-flow .react-flow__edge .react-flow__edge-interaction {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.react-flow .react-flow__edge .react-flow__edge-interaction.highlighted {
  opacity: 0.4;
  filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 0.4));
}
