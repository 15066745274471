@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --inter-font-family: "Inter";
}

@supports (font-variation-settings: normal) {
  :root {
    --inter-font-family: "Inter var";
  }
}

:root {
  --fallback-group-ring-color: theme("colors.border.focus");
}

@supports selector(:has(:focus-visible)) {
  :root {
    --fallback-group-ring-color: transparent;
  }
}

:root {
  --ui-font-family: var(--inter-font-family), system-ui, sans-serif;
  --color-border-default: theme("colors.border.default");
  --color-border-bright: theme("colors.border.bright");
  --color-text-bright: theme("colors.text.bright");
}

html {
  color-scheme: dark;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--ui-font-family);
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svg-child-overflow-visible svg {
  overflow: visible;
}

a:not([href]) {
  @apply saturate-0 pointer-events-none select-none text-text-muted;
}

a:not([href]) * {
  @apply text-text-muted;
}
